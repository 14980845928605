import { Route, Routes } from "react-router-dom";
import Complete from "./pages/complete";
import Main from "./pages/main";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/complete" element={<Complete />}></Route>
      </Routes>
    </>
  );
}

export default App;
