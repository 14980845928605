import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import './stripe.css';
import { useNavigate } from 'react-router-dom';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = (props) => {
    const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [email, setEmail] = useState(null);
    const [showEmailField, setShowEmailField] = useState(false);
    const [hidePayBtn, setHidePayBtn] = useState(false);

    const VAT = 1.2;

    const toggleEmailField = (args) => {
        if (args.target.checked) setShowEmailField(true);
        else setShowEmailField(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setHidePayBtn(true);

        if (!stripe || !elements) return;

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        });

        if (error) {
            setErrorMessage(error.message)
            setHidePayBtn(false);
            return;
        }

        if (paymentIntent.status === 'succeeded') {

            let splitBundleText = props.details.bundle.split(':');
            let amount = parseInt(splitBundleText[0].substring(1, splitBundleText[0].length));

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    custid: props.details.custID,
                    transaction_id: props.details.transactionID,
                    intent: paymentIntent.id,
                    method: paymentIntent.payment_method,
                    value: paymentIntent.amount / 100,
                    email: email,
                    amount: amount
                })
            };

            const response = await fetch(process.env.REACT_APP_SERVER + `/sms/transaction.php`, requestOptions);

            if (response.ok) {
                navigate('/complete')
            }
        }
    };

    let temp = props.details.bundle.split(':')
    let bundlePrice = temp[0].substring(1, temp[0].length);
    let price = ((parseFloat(temp[1]) / 100) * parseFloat(bundlePrice)) * VAT

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <label >Email address for receipt</label>
            <input className='stripe-email-input' type='email' placeholder='example@gmail.com' onChange={e => setEmail(e.target.value)}></input>
            {errorMessage && <div style={{ color: "red", textAlign: "center" }}>{'*' + errorMessage + '*'}</div>}
            {!hidePayBtn ? <button className='stripe_submit' disabled={!stripe}>Pay &pound;{price}</button> : <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "10px"}}>Authenticating <span className="loader"></span></div>}
        </form>
    );
};

export default function StripePayment(props) {
    const [stripeKey, setStripeKey] = useState(null);
    const [stripeSecret, setStripeSecret] = useState(null);

    const VAT = 1.2;

    async function fetchData() {

        let temp = props.details.bundle.split(':')
        let bundlePrice = temp[0].substring(1, temp[0].length);
        let price = ((parseFloat(temp[1]) / 100) * parseFloat(bundlePrice)) * VAT

        const secretOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({
                paymentMethodType: 'card',
                currency: 'gbp',
                amount: parseFloat(price) * 100,
                cust_id: props.details.custID,
                description: `${props.details.custID} - ${bundlePrice} SMS Credits`
            })
        };

        const intent = await (await fetch(process.env.REACT_APP_SERVER + '/stripe/card.php', secretOptions)).json();

        setStripeSecret(intent.client_secret);

    }

    useEffect(() => {
        setStripeSecret(null);
        fetchData();
    }, [props.details]);

    const options = {
        // passing the client secret obtained in step 2
        clientSecret: stripeSecret,
        // Fully customizable with appearance API.
        appearance: { theme: 'stripe' }
    };

    return (
        <>
            {stripeSecret ?
                <Elements stripe={stripePromise} options={options}>
                    <div style={{ width: "700px" }}>
                        <CheckoutForm details={props.details} />
                    </div>
                </Elements>
                :
                <div>Setting up payment request. One moment.</div>
            }
        </>
    );
};