import logo from '../images/dp-text-credits-logo.png'

export default function Complete() {
  return (
    <div style={{display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center"}}>
      <img src={logo} width='376px' height='108px' alt='dentalplus-logo'></img>
      <h1>Your payment went through successfully!</h1>
      <h2>The credits have been added to your account</h2>
    </div>
  )
}
