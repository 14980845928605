import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '.././App.css';
import StripePayment from '.././stripePayment';
import logo from '../images/dp-text-credits-logo.png'

function Main() {
  const [searchParams] = useSearchParams();

  const [prices, setPrices] = useState(null)
  const [details, setDetails] = useState({});
  const [extraInfo, setExtraInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const VAT = 1.2;

  function resetValues () {
    setDetails({})
    setExtraInfo({})
    setPrices(null)
  }

  useEffect(() => {
    if(searchParams.get("cust_id")) {
        fetchPrices(null, searchParams.get("cust_id"))
    }
  }, [searchParams])
  
  async function fetchPrices (e, practice = null) {
      setErrorMessage(null)

      let custID = '';

      if(practice) {
        custID = practice;
      } else {
        if(e.target.value.length < 5) {
            resetValues();
            setErrorMessage('Customer not found!')
            return;
        } 
        custID = e.target.value;
      }

      const response = await fetch(process.env.REACT_APP_SERVER+`/sms/prices.php?custid=${custID}`);

      if(response.ok) {
        let data = await response.json();

        if(data.result === 'error') {
          resetValues();
          setErrorMessage(data.message);
          return;
        }

        if(data.transaction_id === null || data.transaction_id === undefined || data.transaction_id === '' || !data.transaction_id) {
          resetValues();
          setErrorMessage('Problem retrieving customer details please try again later')
          return;
        }

        setDetails({custID: custID, transactionID: data.transaction_id})

        const credits = await fetchCreditsRemaining(custID);

        if(data.last_trans) {
          setExtraInfo({bundle: data.last_trans.amount, date: data.last_trans.datetime, creditsRemaining: credits && credits.credit_remaining, name: data.name})
        } else {
          setExtraInfo({creditsRemaining: credits && credits.credit_remaining, name: data.name})
        }

        let arr = [];

        for (const key in data.prices) {
            arr.push(`${key}:${data.prices[key]}`);
        }

        setPrices(arr);

      } else {
        setErrorMessage(`Couldn't connect to server please try again later`)
        return;
      }
    }

  async function fetchCreditsRemaining (practice) {
    const response = await fetch(process.env.REACT_APP_SERVER+`/sms/credits.php?custid=${practice}`);

    if(response.ok) {
      return await response.json();
    } else {
      return null;
    }
  }
  
  const selectBundle = (args) => {
    setDetails({...details, bundle: args.target.id})
  }

  function debounce(func, timeout = 600){
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  
  const processChange = debounce((e) => fetchPrices(e));
  return (
    <>
        <div className='align-center'><img src={logo} width='376px' height='108px' alt='dentalplus-logo'></img></div>

        <div className='align-center' style={{marginTop: "25px"}}>
          {extraInfo.name ? 
            <div style={{fontSize: "26px", fontWeight: 'bold', textDecoration: 'underline'}}>{extraInfo.name}</div>
          : 
          <>
            <span className='help-text'>Please enter your Customer ID, you can find it in DentalPlus<sup>+</sup></span>
            <br/>
            <br/>
            <input id='cust_id_input' className='customer-id-input' placeholder='Customer ID' autoComplete='off' autoFocus maxLength="20" onKeyUp={e => processChange(e)}></input>
            {errorMessage &&  <><br/>
              <br/>
              <span style={{color: 'red', fontSize: "18px"}}>{errorMessage}</span></>
            }
          </>
          }
        </div>

      <br/>

        {prices &&
        <div>
          {extraInfo.creditsRemaining && <div className='align-center' style={{fontSize: "20px", marginBottom: "10px"}}>You have <strong>{extraInfo.creditsRemaining}</strong> credits remaining</div>}
          {extraInfo.bundle && <div className='align-center' style={{fontSize: "20px"}}>Previously bought <strong>{extraInfo.bundle}</strong> credits on <strong>{extraInfo.date}</strong></div>}

          <br/>

          <div className='bundles'>
            {prices.map(item => {

              let temp = item.split(':')

              let bundlePrice = temp[0].substring(1, temp[0].length);

              let bundle = `${bundlePrice} Credits`;
              let price = `£${(parseFloat(temp[1]) * parseFloat(bundlePrice) / 100)} + VAT`;
              let priceWithVAT = `(£${((parseFloat(temp[1]) * parseFloat(bundlePrice) / 100)) * VAT})`

              return (
                <div key={bundle} id={item} className={item === details.bundle ? 'card-selected' : 'card'} onClick={selectBundle}>
                  <span id={item} style={{fontSize: "20px"}}>{bundle}</span>
                  <br/>
                  <span id={item}>{price}</span>
                  <span style={{fontSize: "15px"}} id={item}>{priceWithVAT}</span>
                </div>
                )
            })
            }
          </div>
        </div>
        }

        <br/>

        {details.bundle && 
          <div style={{display: "flex", justifyContent: "center"}}>
            <StripePayment details={details}/>
          </div>
        }
    </>
  );
}

export default Main;
